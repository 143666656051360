import React from 'react';
import { bool } from 'prop-types';
import { Link } from 'gatsby';

const Menu = ({ open, ...props }) => {
  
  const isHidden = open ? true : false;
  const tabIndex = isHidden ? 0 : -1;
  
  return (
    <>
      <nav open={open} aria-hidden={!isHidden} {...props}>
        <ul>
          <li><Link to="/" tabIndex={tabIndex}>Home</Link></li>
          <li><Link to="/dashboard" tabIndex={tabIndex}>Dashboard</Link></li>
          <li><Link to="/posts" tabIndex={tabIndex}>Posts</Link></li>
          <li><Link to="/about" tabIndex={tabIndex}>About</Link></li>
        </ul>
      </nav>
    </>
  )
}

Menu.propTypes = {
  open: bool.isRequired,
}

export default Menu;